import React from "react"
import Layout from '../components/layout';
import { graphql } from 'gatsby';

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
const PrivacyPage = (props) => {
  return (
    <Layout>


      <div className="columns">
        <div className="column is-2">
        </div>
        <div className="column">
          <h1 className="filled">Privacy Policy</h1>
          <p>National Tool Grinding, Inc, (the parent company of {props.data.site.siteMetadata.title}) has always been committed to maintaining the accuracy, confidentiality, and security of your personal and corporate information. We have established these Privacy Principles to govern our use of customer information.</p>


          <h3>National Tool Grinding </h3>
          <p>does not sell, share or distribute Customer Information to any third party organization.</p>

          <h3>Accountability</h3>
          <p>National Tool Grinding is responsible for maintaining and protecting customer information under its control.</p>

          <h3>Identifying Purposes</h3>
          <p>The purposes for which customer information is collected shall be identified before or at the time the information is collected.</p>

          <h3>Consent</h3>
          <p>The knowledge and consent of the customer is required for the collection, use, or disclosure of customer information, except where required or permitted by law.</p>

          <h3>Limiting Collection</h3>
          <p>The customer information collected must be limited to those details necessary for the purposes identified by National Tool Grinding. Information must be collected by fair and lawful means.</p>

          <h3>Limiting Use, Disclosure, and Retention</h3>
          <p>Customer information may only be used or disclosed for the purpose for which it was collected, unless the customer has otherwise consented, or when it is required or permitted by law. Customer information may only be retained for the period of time required to fulfill the purpose for which it was collected.</p>

          <h3>Accuracy</h3>
          <p>Customer information must be maintained in a accurate, complete, and up-to-date form as is necessary to fulfill the purposes for which it is to be used.</p>

          <h3>Safeguarding Customer Information</h3>
          <p>Customer information must be protected by security safeguards that are appropriate to the sensitivity level of the information.</p>

          <h3>Openness</h3>
          <p>National Tool Grinding is required to make information available to customers concerning the policies and practices that apply to the management of their information.</p>

          <h3>Customer Access</h3>
          <p>Upon request, a customer shall be informed of the existence, use, and disclosure of their information, and shall be given access to it. Customers may verify the accuracy and completeness of their information, and may request that it be amended, if appropriate.</p>

          <h3>Handling Customer Complaints and Suggestions</h3>
          <p>Customers may direct any questions or inquiries with respect to the privacy principles outlined above or about our practices by contacting the designated person(s) accountable for privacy at National Tool Grinding.</p>
          <h3>Opting Out</h3>If you would like to unsubscribe, please send email to <a href="mailto:sales@usnetting.com">sales@usnetting.com</a> or call 1-800-331-2973. You can send U.S. Postal Service mail to:<p></p> <p>National Tool Grinding<br />
            1514 Veshecco Drive<br />
            Erie PA, 16501</p>

          <hr />
          <h1>Return Policy</h1>
          <p>Our policy lasts 14 days. If 14 days have gone by since you received your purchase, unfortunately we can&#x2019;t offer you a refund or exchange.</p>


          <p>To be eligible for a return, your item must be unused, unopened, and in the same condition that you received it. It must also be in the original packaging.</p>
          


          <p>To complete your return, we require a receipt or proof of purchase.</p>

          <p>There are certain situations where only partial refunds are granted: (if applicable)</p>
          <ul>
            <li>Any item not in its original condition, is damaged or missing parts for reasons not due to our error.</li>
            <li>Any item that is returned more than 30 days after delivery</li>
          </ul>

          <h3> Refunds (if applicable)</h3>

          <p>
            Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
       </p>
          <p>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days as determined by credit card processors and your financial institution.</p>
          <h3>Late or missing refunds (if applicable)</h3>
          <p>If you haven&#x2019;t received a refund yet, first check your bank account again. Our parent company is National Tool Grinding Inc and all charges and refunds will be displayed as such.</p>
          <p>Then contact your credit card company, it may take some time before your refund is officially posted.</p>
          <p>Next contact your bank. There is often some processing time before a refund is posted.</p>
          <p> If you&#x2019;ve done all of this and you still have not received your refund yet, please contact us at sales@usnetting.com.</p>

          <h3>Sale items (if applicable)</h3>
          <p>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</p>

          <h3>Exchanges (if applicable)</h3>
          <p>We only replace items if they are defective or damaged.  If you need to exchange it for the same item, send us an email at sales@usnetting.com or call 1-800-331-2973 for instructions.</p>

          <h3>Shipping</h3>
          <p>When shipping an authorized return product, you should mail your product to: 1514 Veshecco Drive, Erie, PA, 16501, United States.</p>
          <p>You will be responsible for paying for your own shipping costs for returning your item unless otherwise stated by accounting. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
          <p>Depending on where you live or operate, the time it may take for your exchanged product to reach you, may vary.</p>
          <p>If you are shipping an item over $78.75, you should consider using a trackable shipping service or purchasing shipping insurance. We don&#x2019;t guarantee that we will receive your returned item.</p>

          <h4>By purchasing a product from {props.data.site.siteMetadata.title} you agree to abide by this Return Policy. </h4>
        </div>
        <div className="column is-1"></div>
      </div>

  <div className="column is-1">
  </div>


  </Layout >
  )

}

export default PrivacyPage;